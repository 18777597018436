import React, { useState, useEffect } from 'react';
import image1 from '../Projects/Pensinea_Yrys/yrys8.jpg';
import image2 from '../Projects/Pensinea_Yrys/yrys9.jpg';

const ImageGallerySection = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const galleryStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    flexDirection: windowWidth < 768 ? 'column' : 'row', // Stack images in a column on mobile
  };

  const imageStyle = {
    width: windowWidth < 768 ? '100%' : '50%', // Adjust image width for mobile
    height: 'auto', // Maintain aspect ratio
    display: 'block',
    marginBottom: '50px', // Add margin between images on mobile
    marginRight:windowWidth < 768 ? '0px' : '5px',
  };

  return (
    <div style={galleryStyle}>
      <img src={image1} alt="Interior Design" style={imageStyle} />
      <img src={image2} alt="Dining Area" style={imageStyle} />
    </div>
  );
};

export default ImageGallerySection;
