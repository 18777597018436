// Navbar.js
import React, { useState, useEffect } from 'react';
import logo from '../Images/logo.png';
import { Link } from 'react-router-dom';
import SocialBar from '../Components/Socialbar';

function Navbar({ enableScrollEffect }) {
  const [scrolling, setScrolling] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [navbarColor, setNavbarColor] = useState('transparent');

  useEffect(() => {
    const handleScroll = () => {
      if (enableScrollEffect && window.scrollY > 500) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [enableScrollEffect]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);

    if (!menuOpen) {
      document.body.classList.add('open-menu');
      setNavbarColor('#000000'); // Culoarea pe care o doriți când meniul este deschis
    } else {
      document.body.classList.remove('open-menu');
      setNavbarColor('transparent');
    }
  };

  const navbarStyle = {
    backgroundColor: enableScrollEffect && scrolling ? '#000000' : navbarColor,
    transition: 'background-color 0.3s ease',
    zIndex: '100',
    position: 'fixed',
    width: '100%',
    fontFamily: "'Indie Flower', cursive",
  };

  if (!enableScrollEffect) {
    navbarStyle.backgroundColor = '#000000';
  }

  return (
    <nav className={`navbar ${menuOpen ? 'open' : ''}`} style={navbarStyle}>
      <Link to="/">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
      </Link>
      <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <li><Link to="/projects">Proiecte</Link></li>
        <li><Link to="/services">Cum lucrăm?</Link></li>
        <li><Link to="/about">Despre</Link></li>
        <li className={`last-item ${window.innerWidth <= 768 ? 'mobile-margin' : ''}`}>
          <Link to="/contact">Contact</Link>
        
        {menuOpen && window.innerWidth <= 768 && <div className="white-line" />}
        </li>
        {menuOpen && window.innerWidth <= 768 && (
        <li className={`social-bar-mobile ${window.innerWidth <= 768 ? 'last' : ''}`}>
          <SocialBar />
          </li>
        )}
      </ul>
      <div className="menu-icon" onClick={toggleMenu}>
        =
      </div>
    </nav>
  );
}

export default Navbar; 