import React, { useState, useEffect } from 'react';

const ServiceSection = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sectionStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
    color: '#fff',
    padding: '70px 0',
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    textAlign: 'left',
  };

  const contentStyle = {
    maxWidth: '300px',
    fontSize: '16px',
    marginBottom: '0px',
    padding: '0 20px',
  };

  const titleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  };

  const mobileSectionStyle = {
    flexDirection: 'column',
    padding: '20px',
    textAlign: 'center',
  };

  const mobileContentStyle = {
    maxWidth: '100%',
    marginBottom: '40px',
    padding: '0 20px',
  };

  const mobileTitleStyle = {
    fontSize: '20px',
  };

  const isMobile = windowWidth < 768;

  return (
    <div style={isMobile ? { ...sectionStyle, ...mobileSectionStyle } : sectionStyle}>
      <div style={isMobile ? { ...contentStyle, ...mobileContentStyle } : contentStyle}>
        <h2 style={isMobile ? { ...titleStyle, ...mobileTitleStyle } : titleStyle}>Design Interior: De la Idee la Realitate</h2>
        <p>Serviciile noastre de design interior sunt menite să facă spațiul dumneavoastră expresiv și funcțional. Acordăm atenție detaliilor, jucăm cu culorile și formele pentru a crea interioare unice care să vă inspire și să vă încânte în fiecare zi.</p>
      </div>
      <div style={isMobile ? { ...contentStyle, ...mobileContentStyle } : contentStyle}>
        <h2 style={isMobile ? { ...titleStyle, ...mobileTitleStyle } : titleStyle}>Dezvoltare și Construcție</h2>
        <p>Echipa noastră oferă un ciclu complet de servicii, de la concept la realizare. Colaborăm îndeaproape cu dumneavoastră la fiecare etapă a proiectului, asigurând cel mai înalt nivel de control și calitate. Rezultatul? Spații fără egal la care ați visat întotdeauna.</p>
      </div>
      <div style={isMobile ? { ...contentStyle, ...mobileContentStyle } : contentStyle}>
        <h2 style={isMobile ? { ...titleStyle, ...mobileTitleStyle } : titleStyle}>Arhitectură Inspirată de Viață</h2>
        <p>Soluțiile noastre arhitecturale încorporează îndrăzneală și inovație. Nu doar construim clădiri; creăm narațiuni. Proiectele noastre se integrează perfect în mediul înconjurător, îmbogățindu-l cu viziunea noastră asupra viitorului.</p>
      </div>
      <div style={isMobile ? { ...contentStyle, ...mobileContentStyle } : contentStyle}>
        <h2 style={isMobile ? { ...titleStyle, ...mobileTitleStyle } : titleStyle}>Renovare și Recondiționare</h2>
        <p>Dacă trebuie să reîmprospătați o proprietate veche sau să dați o nouă viață unui activ recent achiziționat, experții noștri în renovare și recondiționare sunt gata să vă asiste în acest proces. Asigurăm calitate înaltă și timpi de răspuns rapizi pentru lucrare.</p>
      </div>
    </div>
  );
};

export default ServiceSection;
