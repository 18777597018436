import React from 'react';
//import { Link } from 'react-router-dom';
import Navbar from '../Components/Navbar';


import Slideshow_Service from '../Components/Slideshow_Service'; // eslint-disable-next-line react/jsx-pascal-case
import AbSection from '../Components/AbSection';
import ServiceSection from '../Components/ServiceSection';
import ImageGallerySection from '../Components/ImageGallerySection';
import AuthorSupportSection from '../Components/AuthorSupportSection';
import StagesOfWork from '../Components/StagesOfWork';
import ContactSection from '../Components/ContactSection';
import DesignComponent from '../Components/DesignComponent';
//import Carousel from '../Components/Carousel';


const Services = () => {


  return (
    <div>
      <Slideshow_Service />
      <Navbar enableScrollEffect={false} />
      <AbSection/>
      <ServiceSection/>
      <ImageGallerySection/>
      <AuthorSupportSection/>
      <StagesOfWork/>
      <DesignComponent/>
      
      <ContactSection/>
    </div>
  );
};

export default Services;
