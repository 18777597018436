import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import Navbar from '../Components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faPinterest, faBehance} from '@fortawesome/free-brands-svg-icons';
import { faPhone ,faEnvelope} from '@fortawesome/free-solid-svg-icons';

const socialBarStyle = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
};

const iconStyle = {
  fontSize: '20px',
  color: '#ffffff',
  margin: '0 10px',
  transition: 'color 0.3s',
};

const PageWrapper = styled.div`
  background-color: #000000;
  color: #ffffff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 768px) {
    padding-top: 100px; /* Adjust the padding for smaller screens */
  }
  @media (max-height: 590px) {
    padding-top: 200px; /* Adjust the padding for smaller screens */
  }
  padding-bottom: 200px; 
`;

const ContactSection = styled.section`
  opacity: ${props => (props.isVisible ? '1' : '0')};
  transform: translateY(${props => (props.isVisible ? '0' : '20px')});
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  margin-top: 30px;
  
  @media (max-width: 768px) {
    width: 80%; /* Adjust font size for smaller screens */
  
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    font-size: 10px; /* Adjust font size for smaller screens */
  }
`;

const FormInput = styled.input`
  width: ${props => (props.smallWidth ? '50%' : '95%')};
  margin: 10px 0;
  margin-bottom: 50px;
  padding: 10px;
  font-size: 16px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  transition: border-bottom 0.3s;

  &:focus {
    border-bottom: 1px solid #1e1e1e;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #000000 inset;
    box-shadow: 0 0 0 30px #000000 inset;
    caret-color: white; /* Set the caret color to maintain text color */
  }

  &:-webkit-autofill::first-line {
    color: white; /* Set the text color for autofilled email field */
  }

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const FormTextArea = styled.textarea`
  width: 95%; /* Set a fixed width */
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  transition: border-bottom 0.3s;

  &:focus {
    border-bottom: 1px solid #1e1e1e;
  }

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const SendButton = styled.button`
  width: 80%; /* Set a fixed width */
  background-color: #ffffff;
  color: #1e1e1e;
  font-family: 'Nunito';
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;

  &:hover {
    background-color: #1e1e1e;
    color: #ffffff;
  }

  @media (max-width: 768px) {
    width: 50%;
  }
`;

const ContactInfo = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  & > * {
    color: white;
    display: flex;
    align-items: center;
  }

  & > * > svg {
    margin-right: 5px;
  }

  @media (max-width: 768px) {
    width: 100%; /* Adjusted width for better responsiveness */
    flex-direction: column; /* Changed display to flex-direction for stacking on smaller screens */
    align-items: flex; /* Adjusted alignment */
    font-size: 20px;
  }
`;

const SuccessMessage = styled.div`
  color: white;
  font-weight: bold;
  margin-top: 10px;
`;

const LoadingMessage = styled.div`
  color: white;
  margin-top: 10px;
`;



const Contact = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const rect = contactFormRef.current.getBoundingClientRect();
      setIsVisible(rect.top < window.innerHeight && rect.bottom >= 0);
    };

    // Adaugat efectul la incarcarea initiala
    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Setează starea de încărcare

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      city: event.target.city.value,
      message: event.target.message.value,
    };

    try {
      const response = await emailjs.send(
        'service_2wkh275', 'template_c0pvo38', formData, '3LStvFd1W_0kLm7GB'
      );

      console.log('EmailJS Response:', response);
      setSuccessMessage('Trimis cu succes!'); // Setează mesajul de succes

    } catch (error) {
      console.error('EmailJS Error:', error);
      setSuccessMessage('Eroare la trimitere.'); // Setează mesajul de eroare
    } finally {
      setLoading(false); // Setează starea de încărcare înapoi la fals
    }
  };

  const contactFormRef = useRef(null);

  return (
    <PageWrapper>
       <Navbar enableScrollEffect={false} />
      <ContactSection isVisible={isVisible} ref={contactFormRef}>
        <h2 style={{ marginBottom: '14px', fontSize: '40px', fontFamily: 'Nunito' }}>Contact</h2>
        <ContactForm onSubmit={handleSubmit}>
          <div style={{ display: 'flex', width: '100%' }}>
            <FormInput type="text" name="name" placeholder="Nume " style={{ flex: 1, marginRight: '10px' }} smallWidth required />
            <FormInput type="email" name="email" placeholder="Email" style={{ flex: 1 }} smallWidth required />
          </div>
          <div style={{ width: '100%' }}>
            <FormInput type="tel" name="phone" placeholder="Telefon" required />
          </div>
          <div style={{ width: '100%' }}>
            <FormInput type="text" name="city" placeholder="Oras" required />
          </div>
          <FormTextArea name="message" placeholder="Mesaj" rows="4" required />
          <SendButton type="submit" disabled={loading}>Trimite</SendButton>
          {loading && <LoadingMessage>Se trimite...</LoadingMessage>}
          {!loading && <SuccessMessage>{successMessage}</SuccessMessage>}
          <ContactInfo>
            <div>
              <FontAwesomeIcon icon={faPhone} />
              {/* Add 'tel:' prefix to make the phone number clickable */}
              <a href="tel:0720519820">
                <p>0720 519 820</p>
              </a>
            </div>
            <div>
              <FontAwesomeIcon icon={faEnvelope} />
              {/* Add 'mailto:' prefix to make the email address clickable */}
              <a href="mailto:contact@iovainteriors.ro">
                <p>contact@iovainteriors.ro</p>
              </a>
            </div>
          </ContactInfo>
          <div style={socialBarStyle}>
  <a href="https://www.instagram.com/iova.interiors" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faInstagram} style={iconStyle} />
  </a>
  <a href="https://www.facebook.com/iovainteriors" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faFacebook} style={iconStyle} />
  </a>
  <a href="https://ro.pinterest.com/iovainteriors/" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faPinterest} style={iconStyle} />
  </a>
  <a href="https://www.behance.net/iovainteriors" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faBehance} style={iconStyle} />
  </a>
</div>
        </ContactForm>
      </ContactSection>
    </PageWrapper>
  );
};

export default Contact;
