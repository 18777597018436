import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [mobileStyles, setMobileStyles] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const buttonRef = useRef(null);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleHoverLeave = () => {
    setIsHovered(false);
  };

  const handleResize = () => {
    console.log('Window Width:', window.innerWidth);
    setMobileStyles(window.innerWidth <= 768);
  };
  console.log('Component is rendering or useEffect is running');

  useEffect(() => {
    console.log('useEffect is running');
    handleResize();

    const handleScroll = () => {
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  useEffect(() => {
    // On component mount, wait for a short time and then set initialLoad to false
    const timer = setTimeout(() => {
      setInitialLoad(false);
    }, 500);

    // Cleanup timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);

  const aboutSectionStyle = {
    backgroundColor: '#ffffff',
    padding: mobileStyles ? '100px' : '150px',
    textAlign: 'center',
    position: 'relative',
    bottom: mobileStyles ? '40px' : '0px',
  };

  const overlayStyle = {
    position: 'relative',
  };

  const aboutTitleStyle = {
    fontFamily: 'Nunito',
    color: '#f5f5f5',
    fontSize: mobileStyles ? '30px' : '250px',
    margin: '0',
    position: 'relative',
    zIndex: '2',
    top: mobileStyles ? '-30px' : '-180px',
  };

  const subTitleStyle = {
    fontFamily: 'Nunito',
    color: '#000000',
    fontSize: mobileStyles ? '30px' : '60px',
    margin: '0',
    position: 'relative',
    zIndex: '3',
    top: mobileStyles ? '-20px' : '-50px',
    marginBottom: mobileStyles ? '20px' : '0',
    transition: 'opacity 1.5s ease-in-out',
    opacity: isVisible ? '1' : '0',
  };

  const aboutTextStyle = {
    fontFamily: 'Nunito',
    color: '#000000',
    fontSize: mobileStyles ? '14px' : '23px',
    position: 'absolute',
    top: mobileStyles ? '200px' : '240px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '3',
    transition: 'opacity 1.5s ease-in-out',
    opacity: isVisible ? '1' : '0',
    textAlign: 'center',
    width: mobileStyles ? '87.67vw' : 'none',
  };

  const discoverButtonStyle = {
    fontFamily: 'Nunito',
    backgroundColor: '#ffff',
    color: '#050505',
    padding: '15px 30px',
    fontSize: mobileStyles ? '14px' : '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s, border 0.3s ease-out',
    position: 'relative',
    top: mobileStyles ? '350px' : '0px',
    border: initialLoad ? '2px solid transparent' : '2px solid',
    overflow: 'hidden',
    marginTop: mobileStyles ? '270px' : '60px',
  };

  const discoverButtonHoverStyle = {
    backgroundColor: '#808080',
    color: '#ffffff',
  };

  return (
    <section style={aboutSectionStyle}>
      <div style={overlayStyle}>
        <h3 style={subTitleStyle}>IOVA Interiors</h3>
        <h2 style={aboutTitleStyle}>D E S P R E</h2>
        <p style={aboutTextStyle}>
          La IOVA INTERIORS, credem că fiecare spațiu are potențialul de a fi extraordinar. Echipa noastră de designeri pasionați este dedicată transformării mediilor în care trăiți și lucrați în spații captivante și funcționale, reflectând stilul unic. Explorați portofoliul nostru pentru a vedea diversitatea și calitatea lucrărilor noastre. Alăturați-vă nouă într-o călătorie în care visele dvs. întâlnesc designul, și să creăm împreună spații care să vă inspire și să vă ridice calitatea vieții de zi cu zi.
          <br />
          Descoperiți arta designului interior cu IOVA - acolo unde fiecare spațiu spune o poveste unică.
        </p>
        <Link to="/projects" style={{ textDecoration: 'none' }}>
          <button
            ref={buttonRef}
            style={{
              ...discoverButtonStyle,
              ...(isHovered && discoverButtonHoverStyle),
              position: 'relative',
              top: '0',
              display: 'inline-block', // Ensure anchor behaves like a button
            }}
            onMouseEnter={handleHover}
            onMouseLeave={handleHoverLeave}
          >
            <span>Descoperă</span>
          </button>
        </Link>
      </div>
    </section>
  );
};

export default About;
