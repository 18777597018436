import React, { useState, useEffect } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const slideImages = [
  {
    url: require("../Images/main_photo_4.webp"),
    caption: "VIPCAST",
  },
  {
    url: require("../Images/main_photo_3.webp"),
    caption: "Yrys",
  },
  {
    url: require("../Images/main_photo_2.webp"),
    caption: "CASA H",
  },
];

const Slideshow = () => {
  const slideProperties = {
    duration: 8000,
    transitionDuration: window.innerWidth <= 768 ? 300 : 800,
    infinite: true,
    indicators: true,
    arrows: false,
  };

  return (
    <div className="slideshow-container">
      <Slide {...slideProperties}>
        {slideImages.map((imageData, index) => (
          <div key={index} className="each-slide-effect">
            <div style={{ 'backgroundImage': `url(${imageData.url})` }}>
              <AdditionalText caption={imageData.caption} />
              <CursorFollower />
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
};
const CursorFollower = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  const handleMouseMove = (e) => {
    const edgeMargin = 100; // Poate fi ajustat la dimensiunea dorită în pixeli

    // Verifică dacă cursorul se află în marginea ecranului și actualizează isDesktop
    if (
      e.clientX < edgeMargin ||
      e.clientX > window.innerWidth - edgeMargin ||
      e.clientY < edgeMargin ||
      e.clientY > window.innerHeight - edgeMargin
    ) {
      setIsDesktop(false);
    } else {
      setIsDesktop(true);
    }

    setCursorPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseDown = () => {
    setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };
  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!isDesktop) {
    return null; // Returnează null pe dispozitivele mobile
  }

  return (
    <div
      className={`cursor-follower ${isMouseDown ? 'pressed' : ''}`}
      style={{
        left: isMouseDown ? 0 : cursorPosition.x,
      top: isMouseDown ? 0 : cursorPosition.y,
      width: isMouseDown ? '100vw' : 'auto',
      height: isMouseDown ? '100vh' : 'auto',
      backgroundColor: isMouseDown ? 'grey' : 'transparent',
      position: isMouseDown ? 'fixed' : 'absolute',
      zIndex: isMouseDown ? -2 : 'auto',
      transition: `background-color ${isMouseDown ? '0.4s' : '0.2s'} ease-in-out`,
       // Add media query for smaller screens
       '@media (max-width: 768px)': {
        position: 'absolute',
        // Add other styles for smaller screens
      },
    
      }}
    >
      <div className={`cursor-content ${isMouseDown ? 'white-background' : ''}`}>
        <div className="cursor-inner"></div>
        <div className="cursor-arrows">&lt;&nbsp;&nbsp;&gt;</div>
      </div>
    </div>
  );
};


const AdditionalText = ({ caption }) => {
  useEffect(() => {
    // Resetează animațiile pentru a le relua la fiecare schimbare de slide
    const textElement = document.querySelector('.additional-text');
    if (textElement) {
      textElement.style.animation = 'none';
      void textElement.offsetWidth;
      textElement.style.animation = null;
    }

    // Adaugă clasa pentru animația de tipewriter
    const typeInElement = document.querySelector('.additional-text');
    if (typeInElement) {
      typeInElement.classList.add('type-in-animation');
    }
  }, [caption]);

  return (
    <div className="additional-text">
      <p>{caption}</p>
    </div>
  );
};

export default Slideshow; 