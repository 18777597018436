import React, { useState, useEffect } from 'react';
import casav from '../Projects/CASA_V/0fe4bbe2-b0ab-42b7-9c15-0db621195469.jpg';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const StagesOfWork = () => {
  const size = useWindowSize();
  const isMobile = size.width < 768;

  const styles = {
    wrapper: {
      display: 'flex',
      backgroundColor: 'black',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'center' : 'flex-start',
      justifyContent: isMobile ? 'flex-start' : 'space-between',
      
    },
    textContainer: {
      marginTop: isMobile ? '50px' : '100px',
      fontFamily: 'Arial, sans-serif',
      color: 'white',
      width: isMobile ? '90%' : '50%',
      marginLeft: isMobile ? '0' : '50px',
      textAlign: isMobile ? 'center' : 'left',
    },
    stage: {
      position: 'relative',
      paddingLeft: '50px',
      marginBottom: '40px',
    },
    circle: {
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      backgroundColor: 'white',
      position: 'absolute',
      left: '0',
      top: '15px',
    },
    line: {
      position: 'absolute',
      top: '40px',
      left: '14px',
      width: '2px',
      height: isMobile ? '20px' : '100%',
      backgroundColor: 'white',
    },
    imageContainer: {
      width: isMobile ? '90%' : '40%',
      
      marginBottom:'20px',
      marginTop:'20px',
    },
    image: {
      width: isMobile ? '100%' : '80%',
      height: isMobile ? 'auto' : '800px',
    },
    whiteMargin: {
      height: '100px', // Set the height of the white margin
      backgroundColor: 'white', // Set the background color to white
    },
  };

  const stages = [
    { title: 'Contract', text: 'Semnăm un contract și faceți un avans de 30%.' },
    { title: 'Declarație de lucru', text: 'Contactăm măsurătorii din orașul dvs., aceștia fac fotografii și măsurători ale locației pentru noi. Completăm termenii de referință pentru proiect, unde înregistrați toate dorințele dvs. Selectăm analogi ai interioarelor care vă plac.' },
    { title: 'Planificare și colaje', text: 'Creăm o soluție de planificare (2-3 opțiuni), alegem cea mai bună. Plătiți următoarea parte a plății în sumă de - 30%. Creăm colaje care reflectă schema generală de culori, zonarea spațiului și tipurile de materiale de finisare folosite, precum și mobilierul și sursele de lumină (fără a specifica un anumit producător).' },
    { title: 'Vizualizare 3D și documentație', text: 'Plătiți următoarea parte a plății în sumă de - 30%. Creăm imagini fotorealiste ale interiorului, coordonăm toate încăperile. După aprobarea tuturor vizualizărilor 3D, se face un avans - 10% pentru etapa de documentație, pregătim desene și facturi de materiale. Prezentăm un proiect de design finalizat.' },
    { title: 'Implementare', text: 'Efectuăm toate lucrările de construcție și finisare la cheie. Furnizăm un set complet de obiecte și decorăm noua dvs. casă.' },
  ];

  return (
    <div>
      <div style={styles.wrapper}>
        <div style={styles.textContainer}>
          {stages.map((stage, index) => (
            <div key={index} style={styles.stage}>
              <div style={styles.circle}></div>
              {index < stages.length - 1 && <div style={styles.line}></div>}
              <h2>{stage.title}</h2>
              <p>{stage.text}</p>
            </div>
          ))}
        </div>
        <div style={styles.imageContainer}>
          <img src={casav} alt="Design Visualization" style={styles.image} />
        </div>
      </div>
      <div style={styles.whiteMargin}></div>
    </div>
  );
};

export default StagesOfWork;
