import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faPinterest, faBehance } from '@fortawesome/free-brands-svg-icons';

const SocialBar = () => {
  return (
    <div className="social-bar">
      <a href="https://www.instagram.com/iova.interiors" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a href="https://www.facebook.com/iovainteriors" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebook} />
      </a>
      <a href="https://ro.pinterest.com/iovainteriors/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faPinterest} />
      </a>
      <a href="https://www.behance.net/iovainteriors" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faBehance} />
      </a>
    </div>
  );
};

export default SocialBar;
