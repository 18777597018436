import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { Link } from 'react-router-dom';
import ishoImage from '../Projects/Apartament_ISHO/isho2.webp';
import exclusiveImage from '../Projects/Pensiunea_Exclusive/exclusive17.jpg';
import modestoImage from '../Projects/Modesto_Bistro/modesto2.webp';

const SlideshowService = () => {
  const slideImages = [
    {
      url: ishoImage,
      caption: "De la proiect la implementare",
      buttonText: "Cere oferta"
    },
    {
      url: exclusiveImage,
      caption: "Arhitectura inspirata din realitate",
      buttonText: "Cere oferta"
    },
    {
      url: modestoImage,
      caption: "Proiectare si constructie",
      buttonText: "Cere oferta"
    }
  ];

  const properties = {
    duration: 5000,
    transitionDuration: 800,
    infinite: true,
    indicators: true,
    arrows: true
  };

  const handleMouseEnter = (event) => {
    console.log("Mouse entered", event.currentTarget);
    event.currentTarget.classList.remove("dimmed");
  };
  
  const handleMouseLeave = (event) => {
    console.log("Mouse left", event.currentTarget);
    event.currentTarget.classList.add("dimmed");
  };

  return ( 
    <div className="slide-container">
      <Slide {...properties}>
        {slideImages.map((slide, index) => (
          <div key={index} className="each-slide-services dimmed" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div style={{'backgroundImage': `url(${slide.url})`}}>
              <div className="text-overlay">
                <h2>{slide.caption}</h2>
                <Link to="/contact">
                  <button>{slide.buttonText}</button>
                </Link>
              </div>
              {/* Add a class to the image element */}
              <img src={slide.url} alt={slide.caption} className="slide-image" />
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default SlideshowService;
