import React, { useState, useEffect } from 'react';
import comercialImage from '../Images/comercial_1.jpg'; 
import residentialImage from '../Images/rezidential_1.jpg'; 
import architectureImage from '../Images/arhitecture_1.jpg'; 

const ImageSection = () => {
  const [hoveredImage, setHoveredImage] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Verificare inițială la montare
    handleResize();

    // Adăugare ascultător de evenimente pentru redimensionarea ferestrei
    window.addEventListener('resize', handleResize);

    // Eliminare ascultător la demontarea componentei
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleImageHover = (imageName) => {
    if (!isMobile) {
      setHoveredImage(imageName);
    }
  };

  const handleImageLeave = () => {
    if (!isMobile) {
      setHoveredImage(null);
    }
  };

  const desktopImageContainerStyle = {
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    width: hoveredImage ? '750px' : '700%',
    height: hoveredImage ? '400px' : '710px',
    top: '1px',
    margin: '0',
    padding: '0',
    outline: 'none',
    border: hoveredImage ? '2px solid transparent ' : '2px solid #fff',
    borderRadius: '8px',
    transition: 'width 0.9s, height 0.9s, filter 0.5s',
    
    zIndex:'0',
   
  };

  const mobileImageContainerStyle = {
    cursor: 'default',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    top: '100px',
    margin: '0',
    padding: '0',
    outline: 'none',
    borderRadius: '8px',
    transition: 'width 0.9s, height 0.9s, filter 0.5s',
   
    zIndex:'0',
   
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    maxHeight: isMobile ? '300px' : 'none',
    objectFit: isMobile ? 'cover' : (hoveredImage ? 'contain' : 'cover'),
    filter: hoveredImage ? 'none' : 'brightness(0.3)',
    
  };

  const titleStyle = {
    
    fontFamily: 'Nunito',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize:'30px',
    padding: '8px',
    color: hoveredImage ? 'inherit' : '#fff',
    borderRadius: '0px',
    textAlign: 'center',
    width: '80%',
    opacity: hoveredImage ? 0 : 1, 
    border: 'none', 
    zIndex:'100',
  };


  return (
    <section style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: isMobile ? '80px' : '50px', marginBottom: '260px', flexDirection: isMobile ? 'column' : 'row' }}>
      <div
        onMouseEnter={() => handleImageHover('comercial')}
        onMouseLeave={handleImageLeave}
        style={{ ...(isMobile ? mobileImageContainerStyle : desktopImageContainerStyle), ...(hoveredImage === 'comercial' && { width: '950px', height: '800px' }) }}
      >
        <img src={comercialImage} alt="Comercial" style={imageStyle} />
        <h3 style={titleStyle}>Comercial</h3>
      </div>
      <div
        onMouseEnter={() => handleImageHover('residential')}
        onMouseLeave={handleImageLeave}
        style={{ ...(isMobile ? mobileImageContainerStyle : desktopImageContainerStyle), ...(hoveredImage === 'residential' && { width: '1100px', height: '1000px' }) }}
      >
        <img src={residentialImage} alt="Residential" style={imageStyle} />
        <h3 style={titleStyle}>Rezidential</h3>
      </div>
      <div
        onMouseEnter={() => handleImageHover('architecture')}
        onMouseLeave={handleImageLeave}
        style={{ ...(isMobile ? mobileImageContainerStyle : desktopImageContainerStyle), ...(hoveredImage === 'architecture' && { width: '1100px', height: '1000px' }) }}
      >
        <img src={architectureImage} alt="Architecture" style={imageStyle} />
        <h3 style={titleStyle}>Architectural</h3>
      </div>
    </section>
  );
};

export default ImageSection;