import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Components/Navbar';

import ContactSection from '../Components/ContactSection';

const About = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Verificare inițială la montare
    handleResize();

    // Adăugare ascultător de evenimente pentru redimensionarea ferestrei
    window.addEventListener('resize', handleResize);

    // Eliminare ascultător la demontarea componentei
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const cardHoverStyle = isMobile
    ? { transition: 'none' } // Dezactivează tranzitia pe dispozitive mobile
    : {};

  return (
    <div>
      <Navbar enableScrollEffect={false} />

      <div className="ag-format-container ag-courses-container">
        <div className="ag-courses_box">
          {/* Card 1: Design Interior */}
          <div className="ag-courses_item">
            <Link to="/projects" className={`ag-courses-item_link ${isMobile ? 'mobile' : ''}`} style={cardHoverStyle}>
              <div className="ag-courses-item_bg"></div>
              <div className="ag-courses-item_title">
                Design Interior
              </div>
              <div className="ag-courses-item_description">
                Explorează arta de a contura spații interioare care îmbină în mod sublim estetica și funcționalitatea. Pătrunde în universul designului interior, unde fiecare detaliu contribuie la crearea unei atmosfere unice. Descoperă cum să alegi culorile potrivite, mobilierul și accesoriile pentru a transforma fiecare încăpere într-un tablou al eleganței și rafinamentului. Aici, designul devine o expresie a personalității și un mod de a redefine frumusețea în spațiul cotidian.
              </div>
            </Link>
          </div>

          {/* Card 2: Design Mobilier */}
          <div className="ag-courses_item">
            <Link to="/projects" className={`ag-courses-item_link ${isMobile ? 'mobile' : ''}`} style={cardHoverStyle}>
              <div className="ag-courses-item_bg"></div>
              <div className="ag-courses-item_title">
                Design Mobilier
              </div>
              <div className="ag-courses-item_description">
                Intră în lumea fascinantă a designului de mobilier, unde creativitatea și funcționalitatea se întâlnesc într-o armonie perfectă. Descoperă procesul captivant de la concepție la realizare, învățând cum să creezi piese de mobilier care nu doar îndeplinesc nevoile practice, ci și adaugă o notă distinctivă spațiului. Aici, fiecare linie și contur devin o poveste, iar mobilierul devine mijlocul prin care transformi locuința într-o experiență estetică de neuitat.
              </div>
            </Link>
          </div>

          {/* Card 3: Design Fațadă */}
          <div className="ag-courses_item">
            <Link to="/projects" className={`ag-courses-item_link ${isMobile ? 'mobile' : ''}`} style={cardHoverStyle}>
              <div className="ag-courses-item_bg"></div>
              <div className="ag-courses-item_title">
                Design Fațadă
              </div>
              <div className="ag-courses-item_description">
                Pășește în lumea fascinantă a designului de fațadă și descoperă cum poți da viață clădirilor prin exterioruri captivante. În acest spațiu al creativității, vei învăța să integrezi elemente arhitecturale, culori și texturi pentru a conferi fiecărei construcții un caracter aparte. Fiecare proiect devine o poveste arhitecturală, iar fațada devine paleta ta artistică prin care redefinești estetica urbană. Te așteptăm cu drag să discutăm despre nevoile tale.
              </div>
            </Link>
          </div>

          {/* "Cere oferta" Button */}
          {!isMobile && (
            <Link className="button-27" to="/contact" target="_blank" rel="noopener noreferrer">
              Cere Ofertă
            </Link>
          )}
        </div>
      </div>
      <ContactSection />
    </div>
  );
};

export default About;
