import React from 'react';
import styled from 'styled-components';
import { FaPencilRuler, FaSwatchbook, FaTruckMoving, FaBoxOpen, FaPeopleCarry, FaRegLightbulb } from 'react-icons/fa';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
  @media (max-width: 600px) {
  margin-left:30px;
  
  margin-top:-50px;
  
  }
`;

const Title = styled.h1`
  text-align: center;
  font-size: 45px;
  margin-right:700px;
  margin-bottom: 80px;
  @media (max-width: 600px) {
    font-size: 30px;
    margin-bottom: 70px;
    margin-right:50px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column; // Pe telefon, coloanele vor fi afișate unul sub altul
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(33.333% - 10px); // Aici poți regla spațiul dintre coloane

  @media (max-width: 600px) {
    width: 100%; // Pe telefon, coloana va ocupa toată lățimea
    margin-bottom: 20px; // Adaugă spațiu între elementele coloanei pentru telefon
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 60px;

  @media (max-width: 600px) {
    flex-direction: flex; // Pe telefon, iconița și textul vor fi afișate unul sub altul
    align-items: flex-start; // Aliniază la stânga pentru telefon
    
  }
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  font-size: 50px;

  @media (max-width: 600px) {
    margin-right: 40; // Pe telefon, iconița nu va avea spațiu la dreapta
    margin-bottom: 10px; // Spațiu sub iconiță pentru telefon
    font-size: 20px;
  }
`;
const Text = styled.span`

  font-size: 20px; // Text normal pentru ecran mare
  margin-right:70px;
  @media (max-width: 600px) {
    font-size: 17px; // Text mai mic pentru telefon
  }
`;


const DesignComponent = () => {
    return (
        <Container>
          <Title>Care este rezultatul final?</Title>
          <Row>
            <Column>
              <Item>
                <IconWrapper><FaPencilRuler /></IconWrapper>
                <Text>Soluție de amenajare spațială gândită până în cel mai mic detaliu</Text>
              </Item>
              <Item>
                <IconWrapper><FaSwatchbook /></IconWrapper>
                <Text>Desene structurale inteligente</Text>
              </Item>
              <Item>
                <IconWrapper><FaTruckMoving /></IconWrapper>
                <Text>Managementul construcției</Text>
              </Item>
            </Column>
            <Column>
              <Item>
                <IconWrapper><FaBoxOpen /></IconWrapper>
                <Text>Concept de design interior în stilul dvs. unic și pentru nevoile dvs.</Text>
              </Item>
              <Item>
                <IconWrapper><FaPeopleCarry /></IconWrapper>
                <Text>Selecția materialelor în funcție de bugetul dvs. - o estimare completă a proiectului</Text>
              </Item>
              <Item>
                <IconWrapper><FaRegLightbulb /></IconWrapper>
                <Text>Organizarea furnizării mobilierului și materialelor</Text>
              </Item>
            </Column>
            <Column>
              <Item>
                <IconWrapper><FaPencilRuler /></IconWrapper>
                <Text>Vizualizare 3D a interiorului dvs.</Text>
              </Item>
              <Item>
                <IconWrapper><FaSwatchbook /></IconWrapper>
                <Text>Supravegherea designerului</Text>
              </Item>
              <Item>
                <IconWrapper><FaBoxOpen /></IconWrapper>
                <Text>Decorarea finală</Text>
              </Item>
            </Column>
          </Row>
        </Container>
      );
      
    };


export default DesignComponent;
