import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faPinterest, faBehance } from '@fortawesome/free-brands-svg-icons';

const ContactSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const rect = document.getElementById('contactSection').getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom >= 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const socialBarStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  };

  const iconStyle = {
    fontSize: '20px',
    color: '#ffffff',
    margin: '0 10px',
    transition: 'color 0.3s',
  };

  const contactSectionStyle = {
    backgroundColor: '#000000',
    color: '#ffffff',
    padding: '30px',
    textAlign: 'center',
    transition: 'color 2.0s',
  };

  const contactTextStyle = {
    color: '#808080',
    fontFamily: 'Nunito',
    fontSize: '13px',
    lineHeight: '1.5',
    marginBottom: '20px',
    opacity: '0',
    marginLeft: '0',
    transition: 'opacity 3.0s ease-in-out',
    bottom: '60px',
  };

  const contactEmailStyle = {
    fontFamily: 'Nunito',
    fontSize: '15px',
    fontWeight: 'bold',
    opacity: '0',
    marginLeft: '0',
    transition: 'opacity 3.0s ease-in-out',
  };

  const developedByStyle = {
    fontSize: '10px',
    color: '#666',
    fontFamily: 'Nunito',
    marginTop: '20px',
  };

  const linkedInStyle = {
    fontSize: '10px',
    color: '#ffffff',
    fontFamily: 'Nunito',
  };

  return (
    <section id="contactSection" style={contactSectionStyle}>
      <div>
        <h2 style={{ marginBottom: '14px', transition: 'color 0.5s', fontSize: '20px', fontFamily: 'Nunito', marginLeft: '0' }}>Contact</h2>
        <br />
        <p style={{ ...contactEmailStyle, opacity: isVisible ? '1' : '0' }}>contact@iovainteriors.ro</p>
        <p style={{ ...contactTextStyle, opacity: isVisible ? '1' : '0' }}>
          Suntem pregătiți să vă călăuzim în lumea <br/> fascinantă a arhitecturii și designului interior.
        </p>
       
        <Link to="/contact">
  <button
    style={{
      fontFamily: 'Nunito',
      fontSize: '14px',
      padding: '10px 20px',
      backgroundColor: '#ffffff',
      color: '#000000',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s, color 0.3s',
      marginTop: '10px',
    }}
  >
    Contactează-ne
  </button>
</Link>
<br></br>
<br></br>
<br></br>

<div style={socialBarStyle}>
  <a href="https://www.instagram.com/iova.interiors" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faInstagram} style={iconStyle} />
  </a>
  <a href="https://www.facebook.com/iovainteriors" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faFacebook} style={iconStyle} />
  </a>
  <a href="https://ro.pinterest.com/iovainteriors/" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faPinterest} style={iconStyle} />
  </a>
  <a href="https://www.behance.net/iovainteriors" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faBehance} style={iconStyle} />
  </a>
</div>
        <br></br><br></br>
    

        <p style={developedByStyle}>
          developed by&nbsp;
          <a href="https://www.linkedin.com/in/alin-tamas-a14543223/" target="_blank" rel="noreferrer" style={linkedInStyle}>
            @Alin
          </a>
          &nbsp;with <a href="https://react.dev/learn/" target="_blank" rel="noreferrer" style={linkedInStyle}>
            @ReactJS
          </a>
        </p>
      </div>
    </section>
  );
};

export default ContactSection;