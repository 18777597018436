import React, { useState, useEffect } from 'react';

const AbSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sectionStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '100px',
    background: '#ffffff',
    color: '#000000',
    fontFamily: 'Arial, sans-serif',
  };

  const textStyle = {
    fontSize: '3em',
    fontWeight: 'bold',
    marginBottom: '0.5em',
  };

  const paragraphStyle = {
    fontSize: '1.5em',
    marginBottom: '0.5em',
  };

  const statsStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  };

  const statItemStyle = {
    fontSize: '2.5em',
    fontWeight: 'bold',
    marginBottom: '0.3em',
  };

  const statDescriptionStyle = {
    fontSize: '1em',
    textTransform: 'uppercase',
    borderBottom: '1px solid #000000',
  };

  // Mobile styles
  const mobileSectionStyle = {
    padding: '20px', // Adjust padding for mobile
  };

  const mobileTextStyle = {
    fontSize: '2em', // Smaller font size on mobile
  };

  const mobileParagraphStyle = {
    fontSize: '0.9em', // Smaller font size on mobile
  };

  const mobileStatItemStyle = {
    fontSize: '2em', // Smaller font size on mobile
    display: 'block',
  };

  const mobileStatDescriptionStyle = {
    fontSize: '0.8em', // Smaller font size on mobile
    display: 'block',
    padding: '5px',
  };

  return (
    <div style={{ ...sectionStyle, ...(isMobile && mobileSectionStyle) }}>
      <div className="about-text">
        <h1 style={{ ...textStyle, ...(isMobile && mobileTextStyle) }}>IOVA Interiors</h1>
        <p style={{ ...paragraphStyle, ...(isMobile && mobileParagraphStyle) }}>COMERCIAL | REZIDENTIAL | ARHITECTURAL</p>
        <p style={{ ...paragraphStyle, ...(isMobile && mobileParagraphStyle) }}>
          Bine ați venit în lumea interioarelor IOVA - o companie care dă viață celor mai îndrăznețe și inspirate concepte din domeniul designului interior și al arhitecturii.
        </p>
        <p style={{ ...paragraphStyle, ...(isMobile && mobileParagraphStyle) }}>
          Nu suntem doar designeri; suntem o companie de amenajare. Aceasta înseamnă că nu ne limităm doar la crearea de concepte și proiecte de design. Ne asumăm întreaga responsabilitate pentru implementarea proiectului - de la ideea inițială până la atingerea finală. Scopul nostru este să creăm nu numai interioare frumoase, ci și să asigurăm utilizarea optimă a spațiului, să luăm în considerare fiecare detaliu și să vă facem spațiul perfect funcțional.
        </p>
      </div>
      <div style={statsStyle} className="about-stats">
        {[
          { number: '10', text: 'ani experienta' },
          { number: '100', text: 'proiecte unice' },
          { number: '100%', text: 'parteneri multumiti' },
          { number: '1k', text: 'aprecieri' },
        ].map((item, index) => (
          <div key={index} className="stat-item">
            <h2 style={{ ...statItemStyle, ...(isMobile && mobileStatItemStyle) }}>{item.number}</h2>
            <p style={{ ...statDescriptionStyle, ...(isMobile && mobileStatDescriptionStyle) }}>{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AbSection;
