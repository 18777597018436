import React,{useState,useEffect,useMemo} from 'react';
//import ImageGallery from 'react-image-grid-gallery';
import Navbar from '../Components/Navbar';
import ContactSection from '../Components/ContactSection';



//importare imagini

import alma1 from '../Projects/Alma_Dulce/alma1.webp';
import vipcast from  '../Projects/VIPCAST/vip1.jpg';
import ap1 from '../Projects/Apartament_C/ap1.webp';
import isho1 from '../Projects/Apartament_ISHO/isho1.webp';
import h1 from '../Projects/Casa_H/h1.webp';
import v1 from '../Projects/CASA_V/085ca812-17de-479b-8db2-d922a09b0063.jpg'
import mercure3 from '../Projects/Hotel_Mercure/mercure_3.webp';
import modesto4 from '../Projects/Modesto_Bistro/modesto4.webp';
import yrys5 from '../Projects/Pensinea_Yrys/yrys5.jpg';
import exclusive17 from '../Projects/Pensiunea_Exclusive/exclusive17.jpg';


const CustomImageGallery = ({ imgArray }) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
      {imgArray.map((image, index) => (
        <div key={index} style={{ flex: '1 0 300px', maxWidth: '500px' }}>
          <img
            src={image.src}
            alt={image.alt}
            style={{
              width: '100%', // Set the width to 100%
              height: '300px', // Set the desired height
              objectFit: 'cover', // Maintain aspect ratio and cover the container
              borderRadius: '8px',
            }}
          />
          <p style={{ textAlign: 'center', marginTop: '8px' }}>{image.caption}</p>
        </div>
      ))}
    </div>
  );
};


const Project = () => {
  const [loading, setLoading] = useState(true);
  const [categoryFilter, setCategoryFilter] = useState('All');
  const [filteredImages, setFilteredImages] = useState([]);
//  const [imageElements, setImageElements] = useState([]);

  const imagesArray  = useMemo(() => [
    {
      alt: "comercial",
      caption: "Alma Dulce",
      src: alma1,
    },
    
  
    {
      alt: "comercial",
      caption: "VIPCAST",
      src: vipcast,
    },

    {
      alt: "rezidential",
      caption: "Apartament C",
      src: ap1,
    },

    {
      alt: "rezidential",
      caption: "Apartament ISHO",
      src: isho1,
    },

    {
      alt: "rezidential",
      caption: "CASA H",
      src: h1,
    },

    {
      alt: "rezidential",
      caption: "CASA V",
      src: v1,
    },

    {
      alt: "comercial",
      caption: "Hotel Mercure",
      src: mercure3,
    },

    {
      alt: "comercial",
      caption: "Modesto Bristo",
      src: modesto4,
    },

    {
      alt: "comercial",
      caption: "Pensiunea Yrys",
      src: yrys5,
    },

    {
      alt: "comercial",
      caption: "Pensiunea Exclusive",
      src: exclusive17,
    },
    


  ], []);

  useEffect(() => {
    const loadImages = () => {
      const imagesToLoad = filteredImages.length;
      let loadedImages = 0;

      const handleImageLoad = () => {
        loadedImages += 1;

        if (loadedImages === imagesToLoad) {
          setLoading(false);
        }
      };

      const handleImageLoadError = () => {
        // Handle image load error if needed
        setLoading(false);
      };

      filteredImages.forEach((image, index) => {
        const imgElement = new Image();
        imgElement.src = image.src;

        imgElement.onload = handleImageLoad;
        imgElement.onerror = handleImageLoadError;
      });
    };

    loadImages();
  }, [filteredImages, imagesArray]); // Include filteredImages in the dependency array

  useEffect(() => {
    const filtered = categoryFilter === 'All'
      ? imagesArray
      : imagesArray.filter(image => image.alt.includes(categoryFilter.toLowerCase()));

    setFilteredImages(filtered);
  }, [categoryFilter, imagesArray]);

  const handleCategoryFilter = category => {
    setLoading(true);
    setCategoryFilter(category);
  };

  const categories = ['All', 'Rezidential', 'Comercial'];

  return (
    <div>
      <Navbar enableScrollEffect={false} />
      <div style={{ margin: '130px 26px', position: 'relative' }}>
        {/* Butoane de filtrare */}
        <div style={{ marginBottom: '20px' }}>
          {categories.map(category => (
            <button
              key={category}
              onClick={() => handleCategoryFilter(category)}
              style={{
                marginRight: '10px',
                padding: '5px 10px',
                backgroundColor: category === categoryFilter ? '#ddd' : '#fff',
                border: 'none',
                color: '#000000',
              }}
            >
              {category}
            </button>
          ))}
        </div>

        {loading && (
          <div className="spinner-container">
            <div className="spinner" />
          </div>
        )}
        {!loading && <CustomImageGallery imgArray={filteredImages} />}
      </div>
      <div style={{ marginTop: '50px' }}>
        <ContactSection />
      </div>
    </div>
  );
};

export default Project;