import React, { useState, useEffect } from 'react';

const AuthorSupervisionSection = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sectionStyles = {
    display: 'flex',
    flexDirection: windowWidth < 768 ? 'column' : 'row',
    alignItems: 'flex-start',
    padding: windowWidth < 768 ? '5px' : '50px',
    fontFamily: 'Arial, sans-serif',
    color: 'black',
    backgroundColor: 'white',
    textAlign: 'left',
  };

  const columnStyles = {
    flex: 1,
    padding: '20px',
  };

  const titleStyles = {
    fontSize: windowWidth < 768 ? '20px' : '50px',
    fontWeight: 'bold',
    marginBottom: '15px',
    marginLeft:'20px',
  };

  const textStyles = {
    fontSize: windowWidth < 768 ? '15px' : '20px',
  };

  return (
    <div style={sectionStyles}>
      <div style={columnStyles}>
        <h2 style={titleStyles}>Asistarea <br/>clientului și <br/> suportul complet<br/> al proiectului</h2>
        
      </div>
      <div style={columnStyles}>
      <p style={textStyles}>
          Nu creăm doar proiecte ci aducem vise la viață. Caracteristica noastră principală este
          supravegherea autorului și suportul complet pe durata proiectului dvs., de la început până la final.
          Fiecare etapă a procesului este gestionată meticulos de echipa noastră de experți pentru a asigura
          o execuție fără cusur și o aliniere completă cu așteptările dvs.
        </p>
        <h3 style={{ fontSize: '18px' }}>Ce include supravegherea autorului?</h3>

        <p style={textStyles}>
          "Asistarea clientului" înseamnă că preluăm toate responsabilitățile și provocările asociate cu proiectul dvs.
          Echipa noastră de profesioniști preia conducerea:
        </p>
        {/* Include the rest of your text content here, following the same pattern */}
      </div>
    </div>
  );
};

export default AuthorSupervisionSection;