import './App.css';
import {  Route ,Routes, BrowserRouter} from "react-router-dom";
import React from 'react';
import Home from './Pages/Home';
import Project from './Pages/Project';
import About from './Pages/About';
import ScrollToTop from './Components/ScrollToUp';
import Services from './Pages/Services';
import Contact from './Pages/Contact';

import 'react-slideshow-image/dist/styles.css';

function App() {
  return (
    
      <BrowserRouter>
      <ScrollToTop /> 
      <Routes>
        <Route index element={<Home />} />
        <Route path="/projects" element={<Project />} />
        <Route path="/about" element={<About />} />
         <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        </Routes>
     </BrowserRouter>
    
  );
}

export default App;
